

import { THEME_ENUM } from "constants/theme.constant";
const notAllowedRoutes = [
  "https://app.complianceguru.co.in/Availability",
  "https://app.complianceguru.co.in/CustomerServiceInfo",
  "https://app.complianceguru.co.in/CancelMeeting",
  "https://app.complianceguru.co.in/ReScheduleMeeting",
  "https://app.complianceguru.co.in/ActivateAccount",
  "https://app.complianceguru.co.in/reset-password",
  "https://app.complianceguru.co.in/login",
  "http://localhost:3000/CustomerServiceInfo",
  "http://localhost:3000/Availability",
  "http://localhost:3000/CancelMeeting",
  "http://localhost:3000/ReScheduleMeeting",
  "http://localhost:3000/ActivateAccount",
  "http://localhost:3000/reset-password",
  "http://localhost:3000/login",
  "http://localhost:3000/CreatedExpenseClaims",
  "https://app.complianceguru.co.in/CreatedExpenseClaims",
  "http://localhost:3000/ApproveRejectLeave",
  "https://app.complianceguru.co.in/ApproveRejectLeave",
  "http://localhost:3000/ActionSuccessfully",
  "https://app.complianceguru.co.in/ActionSuccessfully",
];

const userDetailsJSON = localStorage.getItem("userDetails");
const userDetails = JSON.parse(userDetailsJSON);

const userRoles =
  userDetails?.status === "Error"
    ? "" 
    : userDetails?.userRoles?.[0] || "";
let themeConfig;
if (userRoles === "Admin" || userRoles === "Company Admin") {
  const isBlank = notAllowedRoutes.some((url) =>
    window.location.href.includes(url)
  );
  if (isBlank) {
    themeConfig = {
      themeColor: "indigo",
      direction: THEME_ENUM.DIR_LTR,
      mode: THEME_ENUM.MODE_LIGHT,
      locale: "en",
      primaryColorLevel: 600,
      cardBordered: true,
      panelExpand: false,
      controlSize: "md",
      navMode: THEME_ENUM.NAV_MODE_LIGHT,
      layout: {
        type: THEME_ENUM.LAYOUT_TYPE_BLANK,
        sideNavCollapse: false,
      },
    };
  } else {
    themeConfig = {
      themeColor: "indigo",
      direction: THEME_ENUM.DIR_LTR,
      mode: THEME_ENUM.MODE_LIGHT,
      locale: "en",
      primaryColorLevel: 600,
      cardBordered: true,
      panelExpand: false,
      controlSize: "md",
      navMode: THEME_ENUM.NAV_MODE_LIGHT,
      layout: {
        type: THEME_ENUM.LAYOUT_TYPE_STACKED_SIDE,
        sideNavCollapse: false,
      },
    };
  }
} else {
  const isBlank = notAllowedRoutes.some((url) =>
    window.location.href.includes(url)
  );

  if (isBlank) {
    themeConfig = {
      themeColor: "indigo",
      direction: THEME_ENUM.DIR_LTR,
      mode: THEME_ENUM.MODE_LIGHT,
      locale: "en",
      primaryColorLevel: 600,
      cardBordered: true,
      panelExpand: false,
      controlSize: "md",
      navMode: THEME_ENUM.NAV_MODE_LIGHT,
      layout: {
        type: THEME_ENUM.LAYOUT_TYPE_BLANK,
        sideNavCollapse: false,
      },
    };
  } else {
    themeConfig = {
      themeColor: "indigo",
      direction: THEME_ENUM.DIR_LTR,
      mode: THEME_ENUM.MODE_LIGHT,
      locale: "en",
      primaryColorLevel: 600,
      cardBordered: true,
      panelExpand: false,
      controlSize: "md",
      navMode: THEME_ENUM.NAV_MODE_LIGHT,
      layout: {
        type: THEME_ENUM.LAYOUT_TYPE_MODERN,
        sideNavCollapse: false,
      },
    };
  }
}

export { themeConfig };
