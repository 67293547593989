import BaseService from "./BaseService";
import { toast, Notification } from "components/ui";

const ApiService = {
  fetchData(param) {
    return new Promise((resolve, reject) => {
      BaseService(param)
        .then((response) => {
          resolve(response);

          if (response?.data?.message || response?.data?.response) {
            toast.push(
              <Notification
                title={"Successfully Done"}
                type="success"
                duration={2500}
              >
                {response.data.message || response.data.response}
              </Notification>,
              {
                placement: "top-center",
              }
            );
          }
        })
        .catch((errors) => {
          const errorResponse = errors.response?.data;

          if (errorResponse) {
            const errorTitle = errorResponse.title || "Validation Error";

            // Extract the validation error details
            const validationErrors = errorResponse.errors;
            const errorMessages = validationErrors
              ? Object.entries(validationErrors)
                .map(([ messages]) => messages.join(", ")) 
                .join(" | ") 
              : "An unexpected error occurred.";

            toast.push(
              <Notification title={errorTitle} type="danger" duration={4000}>
                {errorMessages}
              </Notification>,
              {
                placement: "top-center",
              }
            );
          } else {
            // Default error message for unexpected issues
            toast.push(
              <Notification title={"Error"} type="danger" duration={4000}>
                {"An unexpected error occurred."}
              </Notification>,
              {
                placement: "top-center",
              }
            );
          }

          reject(errorResponse?.title || "An unexpected error occurred.");
        });
    });
  },
};

export default ApiService;


